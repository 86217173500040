import React from 'react'
import styles from './GoogleAd.module.scss'

declare global {
  interface Window {
    adsbygoogle: unknown[]
  }
}

type Props = {
  block?: number
}

export default class GoogleAd extends React.Component<Props> {
  showAds = process.env.NEXT_PUBLIC_SHOW_ADS === 'true'
  componentDidMount() {
    if (this.showAds) {
      try {
        ;(window.adsbygoogle = window.adsbygoogle || []).push({})
      } catch (error) {
        console.error(error)
      }
    }
  }

  render() {
    const { block = 1 } = this.props
    if (process.env.NEXT_PUBLIC_SHOW_ADS !== 'true') {
      return (
        <div className={styles.fakeAd}>
          Aqui terá um anúncio. Bloco {block}.
        </div>
      )
    }
    return (
      <div className={styles.adWrap}>
        {block === 1 && (
          <>
            {/* <!-- CO3D BLOCO 1 --> */}
            <ins
              className='adsbygoogle'
              style={{ display: 'block' }}
              data-ad-client='ca-pub-9009656290738667'
              data-ad-slot='6046149547'
              data-ad-format='auto'
              data-full-width-responsive='true'
            />
          </>
        )}
        {block === 2 && (
          <>
            {/* <!-- CO3D BLOCO 2 --> */}
            <ins
              className='adsbygoogle'
              style={{ display: 'block' }}
              data-ad-client='ca-pub-9009656290738667'
              data-ad-slot='3419986203'
              data-ad-format='auto'
              data-full-width-responsive='true'
            />
          </>
        )}
      </div>
    )
  }
}
